<template>
  <div>

    {{ monthName }}

  </div>
</template>

<script>

export default {
  name: "period-with-index-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },
  computed: {
    monthName(){
      const date = new Date(Date.UTC(2024, this.row.period_index-1, 2, 3, 0, 0));
      const monthName = date.toLocaleDateString('ru-RU', { month: 'long'});
      return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    }
  },

  data() {
    return {}
  },

  watch: {},
  methods: {},

}
</script>

<style>

</style>